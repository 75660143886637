@import '../../assets/style/variables.module.scss';

@import '../../assets/style/variables.module.scss';

.wrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 70px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  gap: 30px;

  .info {
    width: 500px;
    margin-top: 60px;
    h2 {
      font-weight: 500;
      font-size: 28px;
      letter-spacing: 0.24em;
      color: $--dark-blue;
      margin-bottom: 50px;
    }
    h3 {
      font-weight: 500;
      font-size: 28px;
      letter-spacing: 0.04em;
      color: $--dark-blue;
      margin-bottom: 30px;
      line-height: 159.4%;

      span {
        font-style: italic;
        font-weight: 300;
      }
    }
  }

  .eyeIllustration {
    position: absolute;
    right: -30px;
  }

  .socialMedia {
    display: flex;
    gap: 15px;
  }

  .map {
    margin-top: 50px;
    box-shadow: 0px 0px 51px 10px rgba(32, 86, 156, 0.15);
  }
}
