@import '../../assets/style/variables.module.scss';

.directorCard {
  display: flex;
  flex-direction: column;
  align-items: center;

  .image {
    width: 100%;
    position: relative;

    .circle {
      display: none;
      opacity: 0;
      background: transparent;
      color: transparent;
      transition: all 0.5s ease;
    }

    &:hover {
      .circle {
        opacity: 1;
        width: 102%;
        height: 102%;
        background: $--orange;
        color: $--dark-blue;
        top: -1px;
        left: -1px;
        position: absolute;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 28px;
        line-height: 127.5%;
        letter-spacing: 0.315em;
        text-align: center;
      }
    }

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  h5 {
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.04em;
    color: $--dark-blue;
    margin-top: 35px;
  }
}
