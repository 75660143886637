@import '../../assets/style/variables.module.scss';

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;

  .wrapper {
    width: 100%;
    max-width: $--max-width-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;
      gap: 40px;

      li {
        a {
          color: $--dark-blue;
          font-size: 12px;
          letter-spacing: 0.04em;
          font-weight: 500;
        }
      }
    }

    label {
      background: $--gray;
      border: 1px solid $--dark-blue;
      padding: 8px 10px;
      display: flex;
      align-items: center;
      input {
        color: $--dark-blue;
        font-size: 12px;
        font-weight: 500;
        background: transparent;
        &::placeholder {
          color: rgba(54, 101, 166, 0.46);
        }
      }
    }
  }
}
