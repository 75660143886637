@import '../../assets/style/variables.module.scss';

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $--dark-blue;
  .wrapper {
    width: 100%;
    max-width: $--max-width-desktop;
    display: flex;
    justify-content: space-between;
    padding: 25px 0;

    ul {
      display: flex;
      gap: 30px;
      li {
        a {
          color: $--gray;
          font-size: 12px;
          letter-spacing: 0.04em;
          font-weight: 500;
        }
      }
    }

    h5 {
      font-size: 10px;
      color: $--gray;
      font-weight: 300;
    }
  }
}
