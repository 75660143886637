@import '../../assets/style/variables.module.scss';

.movieCard {
  img {
    width: 100%;
    height: 228px;
    object-fit: cover;
    object-position: center;
  }
  .sessionWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 10px;
    width: 110%;

    a {
      background: $--dark-blue;
      color: $--gray;
      padding: 8px 6px 6px 8px;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.23em;
      border-radius: 2px;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 12px;
    line-height: 127.5%;
    letter-spacing: 0.04em;
    color: $--dark-blue;
    margin-top: 18px;
  }

  h5 {
    font-weight: 300;
    font-size: 12px;
    letter-spacing: 0.04em;
    color: $--dark-blue;
    margin-top: 8px;
  }

  h6 {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.04em;
    line-height: 127.5%;
    margin-top: 8px;
  }

  .orange {
    color: $--orange;
  }
  .green {
    color: $--green;
  }
}
