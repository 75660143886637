@import '../../assets/style/variables.module.scss';

.movieRow {
  width: 100%;
  max-width: $--max-width-desktop;
  margin-bottom: 70px;

  .title {
    margin-bottom: 35px;
    font-weight: 500;
    font-size: 28px;
    letter-spacing: 0.24em;
    color: $--dark-blue;
  }

  .movieWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 40px;
  }

  .orange {
    color: $--orange;
  }
  .green {
    color: $--green;
  }
}
