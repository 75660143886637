@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;
  display: flex;
  flex-direction: column;
  margin-top: 50px;

  .top {
    display: flex;
    gap: 80px;

    .main {
      border-radius: 50%;
      height: 620px;
    }

    .info {
      h1 {
        color: $--dark-blue;
        font-weight: 500;
        font-size: 26px;
        letter-spacing: 0.04em;
      }

      p {
        color: $--dark-blue;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 0.04em;
        line-height: 164.5%;
        margin-top: 30px;
        max-width: 560px;
      }
    }

    .movie {
      display: flex;
      gap: 43px;
      margin-top: 80px;

      h3 {
        font-size: 14px;
        color: $--dark-blue;
        font-weight: 500;
        line-height: 127.5%;
        letter-spacing: 0.04em;
      }
      h4 {
        font-weight: 300;
        font-size: 12px;
        line-height: 127.5%;
        color: $--dark-blue;
        margin-top: 10px;
        letter-spacing: 0.04em;
      }

      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 127.5%;
        letter-spacing: 0.04em;
        margin-top: 10px;
        color: $--orange;
      }

      .sessionWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 20px;
        width: 120%;

        .session {
          h6 {
            font-weight: 500;
            font-size: 18px;
            line-height: 159.4%;
            letter-spacing: 0.24em;
            color: $--dark-blue;
            margin-bottom: 10px;
          }
          .buttonWrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    height: 100px;
    .illustration {
      position: relative;
      left: -350px;
      height: 319px;
      top: -300px;
    }
  }
}
