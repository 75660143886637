@font-face {
  font-family: 'Apercu';
  src: url('../font/Apercu-Medium.woff2') format('woff2'),
    url('../font/Apercu-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('../font/Apercu-Regular.woff2') format('woff2'),
    url('../font/Apercu-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('../font/Apercu-Light.woff2') format('woff2'),
    url('../font/Apercu-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('../font/Apercu-Italic.woff2') format('woff2'),
    url('../font/Apercu-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Apercu';
  src: url('../font/Apercu-MediumItalic.woff2') format('woff2'),
    url('../font/Apercu-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

$--Apercu: 'Apercu', sans-serif;

//COLORS

$--light-blue: #d5eef6;
$--dark-blue: #3667a6;
$--green: #057d7b;
$--orange: #ff7d7b;
$--gray: #f2f2f0;

$--max-width-desktop: 1200px;

body,
input,
select,
textarea {
  font-family: $--Apercu;
}

body {
  background: $--light-blue;
}

button {
  background: transparent;
  cursor: pointer;
  font-family: $--Apercu;
}
