@import '../../assets/style/variables.module.scss';

.widget {
  width: 200px;
  color: $--dark-blue;

  h3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }

  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 127.5%;
    margin-top: 20px;
    margin-bottom: 20px;
    span {
      font-style: italic;
      font-weight: 400;
    }
  }

  .acordion {
    background: $--gray;
    width: 100%;
    font-size: 14px;
    border: 1px solid $--dark-blue;
    display: flex;
    justify-content: space-between;
    padding: 13px;
    border-radius: 2px;
    color: $--dark-blue;
    margin-bottom: 10px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    margin-top: 20px;

    li {
      h5 {
        margin-bottom: 10px;
      }
      .buttonWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
    }
  }
}
