@import '../../assets/style/variables.module.scss';

.wrapper {
  display: flex;
  width: 100%;
  max-width: $--max-width-desktop;
  gap: 65px;
  margin-top: 50px;
  min-height: 80vh;

  img {
    height: 620px;
  }
  .sessionWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .session {
      h5 {
        letter-spacing: 0.24em;
        font-weight: 500;
        font-size: 18px;
        color: $--dark-blue;
        margin-bottom: 15px;
        span {
          font-style: italic;
          font-weight: 300;
        }
      }
      .buttonWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
    }
  }

  .info {
    width: 300px;
    h1 {
      font-weight: 500;
      font-size: 26px;
      line-height: 127.5%;
      letter-spacing: 0.04em;
      color: $--dark-blue;
      font-size: 26px;
      margin-bottom: 30px;
    }

    p {
      font-size: 16px;
      line-height: 164.5%;
      letter-spacing: 0.04em;
      color: $--dark-blue;
      margin-bottom: 30px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      li {
        h5 {
          font-weight: 400;
          font-size: 12px;
          letter-spacing: 0.04em;
          color: $--dark-blue;
        }
        h6 {
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 0.04em;
          color: $--dark-blue;
          margin-top: 7px;
          line-height: 164.5%;
          max-width: 200px;
        }
      }
    }
  }
}
