@import '../../assets/style/variables.module.scss';

.pdfButton {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin: 45px 0;

  button {
    background: $--green;
    padding: 10px 30px 10px 20px;
    border-radius: 3px 0px 0px 3px;
    display: flex;
    align-items: center;
    gap: 25px;
    color: $--gray;
    font-size: 12px;
    letter-spacing: 0.04em;
    font-weight: 300;
  }
}
