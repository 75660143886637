@import '../../assets/style/variables.module.scss';

.wrapper {
  width: 100%;
  max-width: $--max-width-desktop;

  .top {
    margin-top: 45px;
    display: flex;
    gap: 180px;
    justify-content: space-between;

    .head {
      width: 750px;
      img {
        width: 100%;
      }

      p {
        font-weight: 500;
        font-size: 22px;
        line-height: 159.4%;
        letter-spacing: 0.04em;
        color: $--dark-blue;
        margin-top: 45px;
      }
    }
  }
}
