@import '../../assets/style/variables.module.scss';

.wrapper {
  display: flex;
  margin-top: 50px;
  margin-bottom: 70px;
  justify-content: space-between;
  width: 100%;
  max-width: $--max-width-desktop;
  position: relative;

  .decor {
    position: relative;
    left: -25%;
  }

  .aboutImage {
    position: absolute;
    bottom: 50px;
    left: 0;
  }

  .info {
    width: 750px;
    img {
      width: 100%;
    }

    p {
      font-weight: 500;
      font-size: 22px;
      line-height: 159.4%;
      letter-spacing: 0.04em;
      color: $--dark-blue;
      margin-top: 45px;
    }
  }
}
