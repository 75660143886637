@import '../../assets/style/variables.module.scss';

.contact {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 75px;
  margin-top: 75px;

  .illustration {
    right: -50px;
    position: relative;
  }

  .logo {
    height: 203px;
    width: 280px;
    position: relative;
    left: 250px;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .info {
    width: 500px;
    position: relative;
    left: 140px;
    h2 {
      font-weight: 500;
      font-size: 28px;
      letter-spacing: 0.24em;
      color: $--dark-blue;
      margin-bottom: 50px;
    }
    h3 {
      font-weight: 500;
      font-size: 28px;
      letter-spacing: 0.04em;
      color: $--dark-blue;
      margin-bottom: 30px;
      line-height: 159.4%;

      span {
        font-style: italic;
        font-weight: 300;
      }
    }
  }

  .socialMedia {
    display: flex;
    gap: 15px;
  }
}
