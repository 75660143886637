@import '../../assets/style/variables.module.scss';

.support {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 100px;

  .illustration {
    left: -120px;
    position: relative;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    width: 1000px;
    h3 {
      color: $--dark-blue;
      font-weight: 500;
      font-size: 28px;
      letter-spacing: 0.24em;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 35px;
      padding: 45px 0;
      position: relative;

      &::after {
        content: '';
        width: 250px;
        height: 2px;
        background: $--dark-blue;
        position: absolute;
        bottom: 0;
        left: 35%;
        -webkit-transform: translateX(-35%);
        transform: translateX(-35%);
      }

      img {
        height: 60px;
        margin-right: 80px;
      }
    }
  }
}
