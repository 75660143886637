@import '../../assets/style/variables.module.scss';

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  max-width: $--max-width-desktop;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}
